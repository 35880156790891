<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 17.0001V8.07048C1 7.40177 1.3342 6.77731 1.8906 6.40637L8.8906 1.73971C9.5624 1.29184 10.4376 1.29184 11.1094 1.73971L18.1094 6.40637C18.6658 6.77731 19 7.40177 19 8.07048V17.0001M1 17.0001C1 18.1047 1.89543 19.0001 3 19.0001H17C18.1046 19.0001 19 18.1047 19 17.0001M1 17.0001L7.75 12.5001M19 17.0001L12.25 12.5001M1 8.00011L7.75 12.5001M19 8.00011L12.25 12.5001M12.25 12.5001L11.1094 13.2605C10.4376 13.7084 9.5624 13.7084 8.8906 13.2605L7.75 12.5001"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
